<template>
  <el-dialog
    :visible.sync="dialogVisible"
    @close="clearClose"
    custom-class="add-regulation-dialog"
    :title="editDetail && editDetail.id ? '编辑' : '添加'"
    width="640px"
  >
    <div slot="footer">
      <el-button @click="reset">重 置</el-button>
      <el-button @click="confirm" type="primary">确 认</el-button>
    </div>
    <el-form
      :model="form"
      :rules="rules"
      ref="form"
      label-position="top"
      label-width="150px"
      size="mini"
    >
      <el-form-item label="规则名称：" prop="ruleName">
        <el-input v-model="form.ruleName" clearable></el-input>
      </el-form-item>
      <el-form-item label="引用规则：" prop="customerId">
        <el-select
          v-model="form.customerId"
          style="width: 100%" 
          filterable @change="handleSelectChange"
          placeholder="请选择"
        >
          <el-option
            v-for="item in customerList"
            :key="item.dictKey"
            :label="item.dictValue +','+ item.cardNo"
            :value="item.dictKey"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="绑定客户：" prop="customerId" required>
        <el-select
          v-model="form.customerId"
          style="width: 100%" 
          filterable @change="handleSelectChange"
          placeholder="请选择"
        >
          <el-option
            v-for="item in customerList"
            :key="item.dictKey"
            :label="item.dictValue +','+ item.cardNo"
            :value="item.dictKey"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="方案名称：" prop="programmeName" required>
        <el-select
          v-model="form.programmeName"
          style="width: 100%"
          filterable
          placeholder="请选择"
        >
          <el-option
            v-for="(item,index) in programmeList"
            :key="index"
            :label="item.dictValue"
            :value="item.dictValue"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="代理商返点：" prop="agentKick">
        <el-input
          v-model="form.agentKick"
          @input="input_num($event, form, 'agentKick', 100)"
          ><template slot="append">%</template></el-input
        >
      </el-form-item>
      <el-form-item label="佣金绩效费率：" prop="brokerage">
        <el-input
          v-model="form.brokerage"
          @input="input_num($event, form, 'brokerage', 100)"
          ><template slot="append">%</template></el-input
        >
      </el-form-item>
      <el-form-item label="其他绩效费率：" prop="otherBrokerage">
        <el-input
          v-model="form.otherBrokerage"
          @input="input_num($event, form, 'otherBrokerage', 100)"
          ><template slot="append">%</template></el-input
        >
      </el-form-item>
      <el-form-item label="状态：" prop="status">
        <el-radio-group v-model="form.status">
          <el-radio :label="true">启用</el-radio>
          <el-radio :label="false">禁用</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import VDistpicker from "v-distpicker";
import EmployeeSelect from "@/components/EmployeeSelect/index.vue";
import { inputNum } from "@/views/routerProjectManagement/components/ProjectList/components/utils.js";
import { OSSDirectPass } from "@/api/oss.js";
import { performanceInsuredPage, performancePlanPage, redactRule } from "@/api/policy";
export default {
  name: "AddRiskDialog",
  props: {
    showDialog: {
      type: Boolean,
      default: false,
    },
    editDetail: {
      type: Object,
      default: null,
    },
  },
  components: { VDistpicker, EmployeeSelect },
  data() {
    return {
      dialogVisible: false,
      topLevelId: 0,
      customerList: [],
      programmeList: [],
      form: {
        ruleName: "",
        customerId: "",
        customerName: "",
        programmeId: "",
        programmeName: "",
        agentKick: "",
        brokerage: "",
        otherBrokerage: "",
        status: true,
      },
      rules: {
        ruleName: [
          { required: true, message: "请输入规则名称", trigger: "change" },
        ],
        customerId: [
          { required: true, message: "请选择绑定客户", trigger: "change" },
        ],
        programmeName: [
          { required: true, message: "请选择方案名称", trigger: "change" },
        ],
        agentKick: [
          { required: true, message: "请输入代理商返点", trigger: "change" },
        ],
        brokerage: [
          { required: true, message: "请选择佣金绩效费率", trigger: "change" },
        ],
        otherBrokerage: [
          { required: true, message: "请输入其他绩效费率", trigger: "change" },
        ],
      },
      satisfactionList: [
        {
          key: 5,
          value: "非常满意",
        },
        {
          key: 4,
          value: "满意",
        },
        {
          key: 3,
          value: "一般",
        },
        {
          key: 2,
          value: "不满意",
        },
        {
          key: 1,
          value: "非常不满意",
        },
      ],
    };
  },
  model: {
    prop: "showDialog",
    event: "change",
  },
  watch: {
    dialogVisible: {
      handler(v) {
        if (!v) {
          this.$emit("change", false);
        }
      },
    },
    showDialog: {
      handler(v) {
        if (v) {
          this.dialogVisible = true;
          if (this.editDetail && this.editDetail.id) {
            this.form = Object.assign({}, this.editDetail);
            this.$forceUpdate();
          }else {
            this.$refs.form?.resetFields();
            this.form = {
              ruleName: "",
              customerId: "",
              customerName: "",
              programmeId: "",
              programmeName: "",
              agentKick: "",
              brokerage: "",
              otherBrokerage: "",
              status: true,
            }
          }
          this.initType();
        } else {
          this.dialogVisible = false;
        }
      },
    },
  },
  mounted() {},
  methods: {
    reset() {
      this.form = Object.assign({}, this.editDetail && this.editDetail.id ? {...this.editDetail} : {
              ruleName: "",
              customerId: "",
              customerName: "",
              programmeId: "",
              programmeName: "",
              agentKick: "",
              brokerage: "",
              otherBrokerage: "",
              status: true,
            });
    },
    confirm() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          redactRule(this.form).then(res => {
            this.$emit('changeList')
            this.dialogVisible = false;
          });
        }
      });
    },
    clearClose() {
      this.reset();
    },
    onSelected(e) {
      console.log(e.area.value);
      this.form.dangerProvince = e.province.value;
      this.form.dangerCity = e.city.value;
      this.form.dangerCounty = e.area.value;
      this.form.riskLocations =
        e.province.value + "-" + e.city.value + "-" + e.area.value;
    },
    input_num(e, tiem, key, max, decimal = 2) {
      inputNum(e, tiem, key, max, decimal);
    },
    handleRemove(file, t1, t2) {
      let ind = this.form[t1].findIndex((val) => val.url == file.url);
      if (this.form[t1][ind].id) {
        this.form.projectRiskFileIds.push(this.form[t1][ind].id);
      }
      this.form[t1].splice(ind, 1);
      !this.form[t1] || !this.form[t1].length ? (this.form[t2] = "") : "";
    },
    async beforeAvatarUpload(file, type, type2) {
      this.$refs.uploadaaa.clearFiles();
      this.form[type2] = "22222222222222222";
      let fileSize = file.size / 1024 / 1024;
      if (fileSize > 5) {
        this.$message({
          message: `上传的文件不能大于${5}MB`,
          type: "warning",
        });
        return;
      }
      const url = await OSSDirectPass(file);
      this.form[type]
        ? this.form[type].push({
            attName: file.name,
            attType: file.name.split(".")[1],
            attSize: fileSize + "M",
            url,
            attUrl: url,
          })
        : (this.form[type] = [
            {
              attName: file.name,
              attType: file.name.split(".")[1],
              attSize: fileSize + "M",
              url,
              attUrl: url,
            },
          ]);
      this.$forceUpdate();
    },
    initType() {
      // programmeList
      performanceInsuredPage({
        likeKey: "",
        pageIndex: 1,
        pageSize: 9999
      }).then((res) => {
        this.customerList = res.data.records.map(val=>{
          return {
            dictValue: val.playersName,
            dictKey: val.playerId,
            cardNo: val.cardNo
          }
        });
      });
      performancePlanPage({
        likeKey: "",
        pageIndex: 1,
        pageSize: 9999
      }).then((res) => {
        this.programmeList = res.data.records.map(val=>{
          return {
            dictValue: val,
            dictKey: val
          }
        });
      });
    },
    handleChangeSalesman() {
      this.$refs.form.validateField("riskPersonnel");
    },
    handleSelectChange(e) {
      let obj = this.customerList.find(val=> val.dictKey == e);
      this.form.customerName = obj.dictValue;
    }
  },
};
</script>

<style lang="scss">
.add-regulation-dialog {
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  .el-dialog__header {
    font-size: 16px;
    font-weight: bold;
    height: 40px;
    line-height: 40px;
    padding: 0 24px;
    background: #fff;
    .el-dialog__title {
      font-weight: bold;
      color: #333333;
    }
    .el-dialog__headerbtn {
      top: 11px;
      .el-dialog__close {
        color: #333;
        font-weight: bold;
      }
    }
  }
  .el-dialog__body {
    padding: 24px;
    padding-top: 0;
  }
  .el-form-item {
    width: calc(100% - 80px) !important;
  }
  .el-form-item__label {
    padding-bottom: 0;
  }
  .avatar-uploader {
    ::v-deep .el-upload {
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      width: 130px;
      height: 130px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }
  }
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    width: 130px;
    height: 130px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 130px;
    height: 130px;
    line-height: 130px;
    text-align: center;
  }
  .avatar {
    width: 130px;
    height: 130px;
    display: block;
  }
}
</style>
