/*
 * @Author: zhuchaowei
 * @Date: 2024-05-21 09:16:23
 * @email: 952698119@qq.com
 * @LastEditors: zhuchaowei
 * @LastEditTime: 2024-05-29 10:15:10
 * @Description:
 */
import { appConfig } from "@/config/appConfig";
import { createHttp } from "@/api/HttpHelper";
import { createMJHttp } from "@/api/MJRequest";
const axios = createHttp(appConfig.base2GUrl);
const SystemAxios = createMJHttp(appConfig.SystemBaseUrl);
const statisticsAxios = createHttp(appConfig.base2GUrl2);

//绩效方案列表
export function getKpiProjectList(params) {
  return SystemAxios.axios({
    url: "/ins-policy/performance/optionPull",
    method: "GET",
    loading: true,
    params,
  });
}

//规则新增
export function addKpiRule(data) {
  return SystemAxios.axios({
    url: "/ins-policy/performance/addOrUpdate",
    method: "POST",
    loading: true,
    data,
  });
}

//获取规则列表
export function getKpiRuleList(data) {
  //数据类型 dataType 通用：1、客户：2、项目：3、通用
  return SystemAxios.axios({
    url: "/ins-policy/performance/paging",
    method: "POST",
    loading: true,
    data,
  });
}

//规则删除
export function deleteKpiRule(params) {
  return SystemAxios.axios({
    url: "/ins-policy/performance/delete",
    method: "GET",
    loading: true,
    params,
  });
}

//获取规则详情
export function getKpiRuleDetail(params) {
  return SystemAxios.axios({
    url: "/ins-policy/performance/echo",
    method: "GET",
    loading: true,
    params,
  });
}

//绩效列表
export function getTotalKpiList(data) {
  return SystemAxios.axios({
    url: "/ins-policy/performanceRecord/recordPage",
    method: "POST",
    loading: true,
    data,
  });
}

//获取绩效总表表头统计
export function getTotalKpiTableHeader(data) {
  return SystemAxios.axios({
    url: "/ins-policy/performanceRecord/getPerformanceTitle",
    method: "POST",
    loading: true,
    data,
  });
}
//绩效管理 - 代理人下拉框
export function getAgentList(data = {}) {
  return SystemAxios.axios({
    url: "/ins-policy/performanceRecord/agentSelectList",
    method: "POST",
    loading: true,
    data,
  });
}

//绩效管理 - 业务员下拉框
export function getSalesmanList(data = {}) {
  return SystemAxios.axios({
    url: "/ins-policy/performanceRecord/salesmanSelectList",
    method: "POST",
    loading: true,
    data,
  });
}
//绩效管理 - 出单类型下拉框
export function getOutTypeList(data = {}) {
  return SystemAxios.axios({
    url: "/ins-policy/performanceRecord/outOrderTypeList",
    method: "POST",
    loading: true,
    data,
  });
}

//员工绩效列表
export function getStaffKpiList(data) {
  return SystemAxios.axios({
    url: "/ins-policy/performanceRecord/userPerformancePage",
    method: "POST",
    loading: true,
    data,
  });
}
//团队长绩效列表
export function getTeamKpiList(data) {
  return SystemAxios.axios({
    url: "/ins-policy/performanceRecord/teamLeaderPerformancePage",
    method: "POST",
    loading: true,
    data,
  });
}
//团队长绩效列表导出
export function exportTeamKpiList(data) {
  return SystemAxios.axios({
    url: "/ins-policy/performanceRecord/teamLeaderPerformanceDownload",
    method: "POST",
    responseType: "blob",
    loading: true,
    timeout: 1800000,
    data
  });
}
//员工绩效列表导出
export function exportStaffKpiList(data) {
  return SystemAxios.axios({
    url: "/ins-policy/performanceRecord/userPerformanceDownload",
    method: "POST",
    responseType: "blob",
    loading: true,
    timeout: 1800000,
    data
  });
}
//员工长绩详情
export function getStaffKpiDetail(data) {
  return SystemAxios.axios({
    url: "/ins-policy/performanceRecord/getUserPerformanceDetail",
    method: "POST",
    loading: true,
    data,
  });
}
//团队长绩详情
export function getTeamKpiDetail(data) {
  return SystemAxios.axios({
    url: "/ins-policy/performanceRecord/getTeamLeaderDetail",
    method: "POST",
    loading: true,
    data,
  });
}
//员工绩效删除
export function deleteStaffKpi(data) {
  return SystemAxios.axios({
    url: "/ins-policy/performanceRecord/cleanEmployeePerformance",
    method: "POST",
    loading: true,
    data,
  });
}

//绩效规则详情
export function getKpiRuleDetailInfo(params) {
  return SystemAxios.axios({
    url: "/ins-policy/performanceRecord/getPerformanceRecordDetail",
    method: "GET",
    loading: true,
    params,
  });
}

//调整绩效规则费
export function adjustKpiRuleFee(data) {
  return SystemAxios.axios({
    url: "/ins-policy/performanceRecord/updateHandlingCharge",
    method: "POST",
    loading: true,
    data,
  });
}

//批量变更支付状态
export function batchChangePayStatus(data) {
  return SystemAxios.axios({
    url: "/ins-policy/performanceRecord/batchPaymentStatus",
    method: "POST",
    loading: true,
    data,
  });
}
//批量变更收款状态
export function batchChangeReceiveStatus(data) {
  return SystemAxios.axios({
    url: "/ins-policy/performanceRecord/batchCollectionStatus",
    method: "POST",
    loading: true,
    data,
  });
}

//部分收款
export function partReceive(params) {
  return SystemAxios.axios({
    url: "/ins-policy/performanceRecord/verifyCollectionStatus",
    method: "GET",
    loading: true,
    params,
  });
}

//绩效记录批量导出
export function exportKpiRecord(data) {
  return SystemAxios.axios({
    url: "/ins-policy/performanceRecord/downloadRecord",
    method: "POST",
    responseType: "blob",
    loading: true,
    timeout: 1800000,
    data,
  });
}

//绩效导入-支付
export function importKpiPay(data) {
  return SystemAxios.axios({
    url: "/ins-policy/performanceRecord/importPayment",
    method: "POST",
    loading: true,
    data,
  });
}

//绩效导入-收款
export function importKpiCollection(data) {
  return SystemAxios.axios({
    url: "/ins-policy/performanceRecord/importCollection",
    method: "POST",
    loading: true,
    data,
  });
}

//收款模板下载
export function downloadKpiTemplate(params) {
  // ture:导入收款模板 false:导入对账模板
  return SystemAxios.axios({
    url: "/ins-policy/performanceRecord/downloadTemplate",
    method: "GET",
    responseType: "blob",
    loading: true,
    timeout: 1800000,
    params,
  });
}


// 绩效应收列表
export const performanceReceivableList = (data) => {
  return SystemAxios.axios({
    url: "/ins-policy/performanceReceivable/pageList",
    method: "POST",
    loading: true,
    data,
  });
};

//绩效应收导入-收款
export function importCollection(data) {
  return SystemAxios.axios({
    url: "/ins-policy/performanceReceivable/importCollection",
    method: "POST",
    loading: true,
    data,
  });
}

//绩效应收导入-收款
export function importCollectionPreview(data) {
  return SystemAxios.axios({
    url: "/ins-policy/performanceReceivable/importCollectionPreview",
    method: "POST",
    loading: true,
    data,
  });
}

// 导出导入收款失败数据
export function downloadKpiPreviewExport(data) {
  // ture:导入收款模板 false:导入对账模板
  return SystemAxios.axios({
    url: "/ins-policy/performanceReceivable/previewExport",
    method: "POST",
    responseType: "blob",
    loading: true,
    timeout: 1800000,
    data,
  });
}

// 绩效应收-收款明细
export const listReceivableDetail = (data) => {
  return SystemAxios.axios({
    url: "/ins-policy/performanceReceivable/listReceivableDetail",
    method: "post",
    loading: true,
    data,
  });
};

// 绩效应收-单条收款
export const verifyCollectionStatus = (data) => {
  return SystemAxios.axios({
    url: "/ins-policy/performanceReceivable/verifyCollectionStatus",
    method: "post",
    loading: true,
    data,
  });
};

