<template>
  <el-select
    v-model="selectDeptName"
    filterable
    remotepopper-class="staff-demo-ruleForm"
    placeholder="请选择所属部门"
    remote
    clearable
    @visible-change="visibleChange"
    :remote-method="remoteMethod"
  >
    <el-option label value>
      <div @click.stop="clickDept">
        <el-tree
          :data="deptOptions"
          :check-strictly="checkStrictly"
          show-checkbox
          ref="deptTree"
          default-expand-all
          node-key="id"
          :props="defaultProps"
          @check-change="changeDeptId"
          :filter-node-method="filterNode"
        ></el-tree>
      </div>
    </el-option>
  </el-select>
</template>

<script>
import { getOrganizationTree, allDeptTree } from "@/api/SystemData";
export default {
  props: {
    deptId: [String, Number, Array],
    multiple: {
      type: Boolean,
      default: false,
    },
    isTenantId: {
      type: Boolean,
      default: true,
    },
    isEmptied: {
      type: Boolean,
      default: true,
    },
    checkStrictly: {
      type: Boolean,
      default: true,
    },
  },
  model: {
    prop: "deptId",
    event: "change",
  },
  watch: {
    deptId: {
      handler(v) {
        if (v && (!this.selectDeptId || !this.selectDeptId.length || v.toString() !== this.selectDeptId.toString())) {
          this.selectDeptId = v;
          if (this.deptOptions && this.deptOptions.length && this.$refs.deptTree) {
            this.$nextTick(()=>{
              if (this.multiple) {
                this.$refs?.deptTree?.setCheckedKeys(this.selectDeptId,true);
              }else {
                this.$refs?.deptTree?.setCheckedKeys([this.selectDeptId],true);
              }
              this.$nextTick(()=> {
                let checkList = this.$refs?.deptTree?.getCheckedNodes();
                if (checkList && checkList.length) {
                  if (this.multiple) {
                    this.selectDeptName = checkList.map((val) => val.title);
                  }else {
                    this.selectDeptName = checkList.map((val) => val.title).toString();
                  }
                }
              })
            })
          }
        }else if (!v || !v.length) {
          this.$refs?.deptTree?.setCheckedKeys([]);
        }
      },
      deep: true,
      immediate: true
    },
    selectDeptName: {
      handler(v) {
        if (!v) {
          this.$emit('change', '');
          this.$emit('changeCheck', {id: this.multiple ? [] : '', name: ''});
          this.$refs.deptTree.setCheckedKeys([]);
        }
      },
    }
  },
  data() {
    return {
      selectDeptName: null,
      selectDeptId: null,
      deptOptions: [],
      defaultProps: {
        children: "children",
        label: "title",
      },
    };
  },
  created() {
    this.init();
  },
  methods: {
    clickDept(e) {
      console.log(e);
    },
    changeDeptId() {
      if (this.multiple) {
        let checkList = this.$refs.deptTree.getCheckedNodes();
        this.selectDeptName = checkList.map((val) => val.title);
        this.selectDeptId = checkList.map((val) => val.id);
        this.$emit('change', this.selectDeptId);
        this.$emit('changeCheck', {id: this.selectDeptId, name: this.multiple ? this.selectDeptName.toString() : this.selectDeptName});
      } else {
        this.selectDeptId ? this.$refs.deptTree.setChecked(this.selectDeptId) : '';
        this.$nextTick(()=>{
          let checkList = this.$refs.deptTree.getCheckedNodes();
          this.selectDeptName = checkList.map((val) => val.title).toString();
          this.selectDeptId = checkList.map((val) => val.id).toString();
          this.$emit('change', this.selectDeptId);
          this.$emit('changeCheck', {id: this.selectDeptId, name: this.multiple ? this.selectDeptName.toString() : this.selectDeptName});
        })
      }

    },
    filterNode(value, data) {
      if (!value) return true;
      return data.title.indexOf(value) !== -1;
    },
    remoteMethod(e) {
      this.$refs.deptTree.filter(e);
    },
    visibleChange(v) {
      if (v&& this.isEmptied) {
        this.$emit('change', '');
        this.$emit('changeCheck', {id: this.multiple ? [] : '', name: ''});
        this.$refs.deptTree.filter("");
      }else if (v && this.selectDeptId) {
        if (this.multiple) {
          this.$refs.deptTree.setCheckedKeys(this.selectDeptId,true);
          this.$forceUpdate();
        }else {
          this.$refs.deptTree.setCheckedKeys([this.selectDeptId],true);
        }
      }
    },
    init() {
      if (this.isTenantId) {
        getOrganizationTree({ tenantId: this.$store.state.tenantId }).then(
          (res) => {
            this.deptOptions = res.data;
            if (this.selectDeptId && res.data && res.data.length) {
              this.initDeptName(res.data);
            }
            if (this.selectDeptName) {
              this.$nextTick(() => {
                if (this.multiple) {
                  this.$refs.deptTree.setCheckedKeys(this.selectDeptId);
                } else {
                  this.$refs.deptTree.setChecked(this.selectDeptId);
                }
                this.changeDeptId();
              });
            }
          }
        );
      }else {
        allDeptTree().then(res=>{
          this.deptOptions = res.data;
            if (this.selectDeptId && res.data && res.data.length) {
              this.initDeptName(res.data);
            }
            if (this.selectDeptName) {
              this.$nextTick(() => {
                if (this.multiple) {
                  this.$refs.deptTree.setCheckedKeys(this.selectDeptId);
                } else {
                  this.$refs.deptTree.setChecked(this.selectDeptId);
                }
                this.changeDeptId();
              });
            }
        })
      }
    },
    initDeptName(data) {
      data.forEach(e => {
        if (this.multiple && this.selectDeptId.includes(e.id)) {
          this.selectDeptName ? this.selectDeptName = e.title : this.selectDeptName += ',' + e.title;
          e.children && e.children.length ? this.initDeptName(e.children) : ''
        }else if (e.id == this.selectDeptId) {
          this.selectDeptName = e.title;
          return false;
          throw '赋值成功';
        }else if(e.children && e.children.length) {
          this.initDeptName(e.children);
        }
      });
    }
  },
};
</script>

<style lang="scss">
.policy-import-dialog {
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  .el-dialog__header {
    font-size: 18px;
    font-weight: bold;
    height: 40px;
    line-height: 40px;
    padding: 0 24px;
    background: #4278c9;
    .el-dialog__title {
      color: #fff;
    }
    .el-dialog__headerbtn {
      top: 11px;
      .el-dialog__close {
        color: #fff;
        font-weight: bold;
      }
    }
  }
  .el-dialog__body {
    padding: 24px;
  }
  &-template {
    height: 60px;
    line-height: 60px;
    background: #f3f8ff;
    font-size: 14px;
    color: #333333;
    border-radius: 8px;
    border: 1px dashed #d1e3fe;
    padding: 0 24px;
    margin-bottom: 12px;
    &-span {
      color: #0080ff;
      cursor: pointer;
    }
  }
  &-b {
    height: auto;
    padding: 10px 24px;
    &-img {
      display: block;
      width: 40px;
      height: 40px;
      margin: 14px auto 12px;
    }
    &-text {
      text-align: center;
      font-size: 18px;
      margin-bottom: 5px;
      color: #333333;
    }
    .upload-demo {
      width: 100%;
      .el-upload {
        width: 100%;
      }
      .el-upload-dragger {
        width: 100%;
        height: auto;
        border: none;
        background-color: transparent;
      }
    }
  }
}
::v-deep .el-dialog__header {
  background: #4b7bca;
  .el-dialog__title {
    color: #fff;
  }
}
.el-select-dropdown__item {
  height: auto;
  background-color: #fff !important;
}
.reset-password {
  display: flex;
}
</style>
