import { appConfig } from '@/config/appConfig';
import { createMJHttp } from '@/api/HttpHelper';
import store from '@/store';

const MJAxios = createMJHttp(appConfig.SystemBaseUrl);

const OssAxios = createMJHttp(appConfig.OSSBaseUrl);


// oss上传签名
export const ossSign = (params) => {
  return MJAxios.axios({
    url: '/file/oss/sign',
    method: 'GET',
    params
  });
};

// oss上传签名
const ossSign1 = (params) => {
  return MJAxios.axios({
    url: '/blade-system/oss/sign',
    method: 'GET',
    params
  });
};

// oss上传
export const OSSUpload = (data, url) => {
  return OssAxios.axios({
    url: url,
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    data: data
  });
};
// oss上传
const OSSUpload1 = (data, url) => {
  return OssAxios.axios({
    url: url,
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    data: data
  });
};

export const OSSDirectPass = (e) => {
  return new Promise((resolve, reject) => {
    ossSign1({
      userId: store.state.userInfo.userId,
      token: store.state.appToken
    }).then(res => {
      let formData = new FormData();
      let time = new Date();
      formData.append('name', e.name);
      formData.append('key', res.data.dir + (time.getTime()) + '/' + e.name);
      formData.append('policy', res.data.policy);
      formData.append('OSSAccessKeyId', res.data.accessid);
      formData.append('success_action_status', 200);
      formData.append('signature', res.data.signature);
      formData.append('file', e.raw);
      OSSUpload1(formData, res.data.host).then(res2 => {
        resolve(`${ res.data.host }/${ res.data.dir }${ time.getTime() }/${ e.name }`);
      }).catch(err => {
        console.log(err);
        reject();
      });
    }).catch(err => {
      reject();
    });
  });
};