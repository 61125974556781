// 格式化限制数字文本框输入，只能数字或者两位小数
export function inputNum(e, tiem, key, max, decimal = 2) {
  // 清除"数字"和"."以外的字符
  e = e.replace(/[^\d.]/g, "");
  // 验证第一个字符是数字
  e = e.replace(/^\./g, "");
  // 只保留第一个, 清除多余的
  e = e.replace(/\.{2,}/g, ".");
  e = e.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
  if (decimal == 2) {
    // 只能输入两个小数
    e ? tiem[key] = e.replace(/^(\-)*(\d+)\.(\d\d).*$/, "$1$2.$3") : tiem[key] = '';
  } else if (decimal == 3) {
    // 只能输入两个小数
    e ? tiem[key] = e.replace(/^(\-)*(\d+)\.(\d\d\d).*$/, "$1$2.$3") : tiem[key] = '';
  }  else if (decimal == 0) {
    // 只能输入两个小数
    e ? tiem[key] = parseInt(e) : tiem[key] = '';
  } else {
    // 只能输入4个小数
    e ? tiem[key] = e.replace(/^(\-)*(\d+)\.(\d\d\d\d).*$/, "$1$2.$3") : tiem[key] = '';
  }

  //如果有小数点，不能为类似 1.10的金额
  // if (e.indexOf(".") > 0 && e.indexOf("0") > 2) {
  //   tiem[key] = parseFloat(e);
  // }
  // //如果有小数点，不能为类似 0.00的金额
  // if (e.indexOf(".") > 0 && e.lastIndexOf("0") > 2) {
  //   tiem[key] = parseFloat(e);
  // }
  //以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
  if (e.indexOf(".") <= 0 && e != "") {
    tiem[key] = parseFloat(e);
  }
  if (e > max) {
    const aa = e + "";
    tiem[key] = parseFloat(aa.slice(0, e.length - 1));
  }
  if (key === "sumInsured") {
    tiem.limitCompensation = tiem.sumInsured;
  }
  if (
    (key === "sumInsured" || key === "rate") &&
    (tiem.sumInsured || tiem.rate)
  ) {
    let respremium = 0;
    if (tiem.rateType) {
      respremium = (
        (Number(tiem.sumInsured) || 0) *
        (Number(tiem.rate) || 0) *
        0.01
      ).toFixed(6);
    } else {
      respremium = (Number(tiem.rate) || 0).toFixed(6);
    }
    if (this.ruleForm.startDate && this.ruleForm.endDate) {
      let timeInterval = dayjs(
        dayjs(this.ruleForm.endDate).format("YYYY-MM-DD")
      ).diff(
        dayjs(this.ruleForm.startDate).format("YYYY-MM-DD"),
        "month",
        true
      );
      timeInterval = Math.ceil(timeInterval);
      respremium *= this.calculationShortTermRates(timeInterval);
    }
    tiem.premium = respremium.toFixed(6);
  }
  //this.InputcalculatePremium(tiem);
}
